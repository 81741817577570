$sylius-brand-color: #1abb9c !default;
$segment-spacing: 1.8em !default;
$segment-title-spacing: 1.2em 1.8em !default;
$accordion-title-spacing: 1.2em 1.8em !default;

// ----------------------------------
// ------------ Globals
// ----------------------------------

.ui.inverted.menu {
    background: #1d212b;
}
body.centered {
    background: url(../img/login-bg.jpg) !important;
    background-size: cover !important;
    overflow: hidden !important;
}

body.pushable{
    background: #f9f9f9 !important;
}

body.pushable .pusher {
    background: #f9f9f9;
}

#wrapper.full.height {
    padding: 64px 12px 0 12px;
}

a {
    color: $sylius-brand-color;
    &:hover { color: $sylius-brand-color; }
}

// ----------------------------------
// ------------ Buttons
// ----------------------------------

.ui.button {
    font-weight: 400;
}

.ui.primary.button {
    background-color: $sylius-brand-color;
    &:hover, &:focus { background-color: #279c7f; }
}

.ui.labeled.icon.button {
    padding-left: 2.8em !important;
}

.ui.labeled.icon.button>.icon,
.ui.labeled.icon.buttons>.button>.icon {
    width: 2.8em !important;
    background: none !important;
    padding-left: .2em;
}

// ----------------------------------
// ------------ Forms
// ----------------------------------

.ui.form .field > label {
    margin: 0 0 .5rem 0;
    color: rgba(0,0,0,.8);
    font-weight: 400;
}

.ui.form .field > input:focus,
.ui.form .field > textarea:focus {
    border-color: rgba($sylius-brand-color, .4);
}

// ----------------------------------
// ------------ Sidebar menu
// ----------------------------------

#sidebar.ui.sidebar.vertical.menu {
    > .item:first-child {
        margin-bottom: 10px;
    }

    > .item:not(:first-child) {
        margin-right: 10px;
    }

    .item > .header {
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 16px;
    }

    .item > i.icon {
        float: none !important;
        margin: 2px 10px 2px 0 !important;
        opacity: .5;
    }

    .item::before {
        display: none;
    }

    .item.active {
        font-weight: inherit !important;
        background: $sylius-brand-color !important;
        border-radius: 0 99px 99px 0 !important;
    }

    &.sidebar-admin-company{
        background: #023068;

        .item{
            color: #fff;
            opacity: 0.9;

            &.active{
                background: #2f598c !important;
                opacity:1;
            }

            .item:hover{
                background: rgba(255, 255, 255, 0.08);
            }
        } 
    }

    &.sidebar-user{
        background: #195454;
    }
}

// ----------------------------------
// ------------ Header
// ----------------------------------

.ui.header {
    .content {
        padding-left: 1.3rem !important;
    }

    .content .sub.header {
        font-size: 1rem;
        color: #9a9a9a;
        padding-top: 3px;
    }

    .circular.icon {
        width: 2.5em !important;
        height: 2.5em !important;
        font-size: 1em;
        color: $sylius-brand-color;
        background: rgba($sylius-brand-color, .1);
        box-shadow: none;
    }
}

.ui.action.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

// ----------------------------------
// ------------ Breadcrumb
// ----------------------------------

.ui.breadcrumb {
    .active {
        color: #9a9a9a;
        font-weight: 400 !important;
    }

    a {
        color: #9a9a9a;
        &:hover { color: $sylius-brand-color; }
    }

    .divider {
        opacity: .4;
    }
}

// ----------------------------------
// ------------ Segment
// ----------------------------------

.ui.segment,
.ui.attached.segment {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
    padding: $segment-spacing;
    width: 100%;
}

.ui.attached.segment {
    margin-left: 0;
    margin-right: 0;
}

.ui.attached.header {
    padding: $segment-title-spacing;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
    border-bottom: 1px solid #eaeaea;
    font-size: inherit;
}

.segment.spaceless {
    padding: 0 !important;
}

// ----------------------------------
// ------------ Accordion
// ----------------------------------

.ui.styled.accordion {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);

    .title {
        padding: $accordion-title-spacing;
        border-top: none;
    }

    .content {
        padding: $segment-spacing;
        border-top: 1px solid #eaeaea;
    }
}

// ----------------------------------
// ------------ Pagination / Menu
// ----------------------------------

.ui.menu {
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.03);
    border: 1px solid rgba(34,36,38,.1);
}

// ----------------------------------
// ------------ Table
// ----------------------------------

.sylius-grid-table-wrapper {
    .ui.sortable.table {
        thead th {
            padding-top: 20px;
            padding-bottom: 20px;

            &.sorted {
                background: inherit;
                color: $sylius-brand-color;
            }
            &.sylius-table-column-date{
                min-width: 100px;
            }
        }
    }
}

.sylius-grid-image {
    max-height: 50px;
    max-width: 50px;
}

// ----------------------------------
// ------------ Toggle
// ----------------------------------

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: $sylius-brand-color !important;
}

// ----------------------------------
// ------------ Tabs
// ----------------------------------

.ui.top.attached.tabular.menu {
    flex-wrap: wrap;
}

// ----------------------------------
// ------------ Statistics
// ----------------------------------

.stats {
    .stats-grid {
        display: flex;
        flex-wrap: nowrap;

        @media (max-width: 1000px) {
            flex-wrap: wrap;
        }
    }

    .stats-column {
        flex-grow: 1;
        flex-shrink: 0;
        width: 25%;
        padding: 20px;
        text-align: center;
        box-shadow: 0 -1px 0 0 #ebebeb, 1px 0 0 0 #ebebeb;

        @media (max-width: 1000px) {
            width: 50%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    .stats-graph {
        height: 400px;
    }

    .stats-loader {
        background: rgba(249, 249, 249, 0.7) !important;
    }

    .ui.basic.active.button {
        color: $sylius-brand-color !important;
    }
}

// ----------------------------------
// ------------ Navigation
// ----------------------------------

.navigation {
    position: absolute;
    top: 5px;
    bottom: 5px;
    margin: 5px;
    width: 40px;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 40px;
    color: grey;
    background-color: transparent;
    text-align: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        color: black;
    }

    i {
        pointer-events: none;
    }

    &:focus {
        outline: 0;
    }
}

.navigation-prev {
    left: 0;
}

.navigation-next {
    right: 0;
}
#list-permissions{
    .accordion{
        .content {
            position: relative;
        }
    }
}

.ui.modal .list-files-cards .card {
    width: calc(100% /3 - 1em) !important;
}
.ui.modal .ui.segment[class*="bottom attached"]{
    margin-bottom: 0px;
}
.sylius-grid-table-wrapper .sylius-table-column-other {
    width: 300px;
}

.ui.avatar.empty {
    background: #7c7c7c;
    border-radius: 50%;
    padding: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon{
        margin:0;
        font-size:0.8em;
        color: #fff;
    }
}
.owl-notification-list{
    .menu{
        padding:0 !important;

        .item{
            border-bottom: 1px solid #dddddd !important;

            a{
                display: flex;
                flex-direction: column;
                color: #000;
                font-weight: bold;
                cursor: pointer;

                .text{
                    font-size:13px;
                }

                .date{
                    margin-left:auto;
                    color: #7c7c7c;
                    font-size:12px;
                    font-style: italic;
                    padding-top: 4px;
                }
            }

            &:last-child{
                border-bottom:0;
            }


        }
    }
}

body{
    &.centered{
        .wrap-registration{
            max-width:800px;

            .wrap-header{
                max-width: 800px;
                padding:0;
            }
        }
    }
}

form[name="owl_reject_registration"] {
    textarea{
        min-height: 8.5em;
        height: 9em !important;
    }
}

form{
    .field{
        position:relative;
        margin-bottom: 1em !important;
        padding-bottom: 0.1em;
        &.error{
            .sylius-validation-error {
                position: absolute;
                left:0;
                top:100%;
                background-color: transparent !important;
                background: transparent !important; 
                color: #DB2828 !important;
                padding-top: 0;
                margin-top: 0em;
                margin-left:0em;
                padding-left:0em;

                &::before{
                    display: none !important;
                }
            }
        }
    }
    .fields{
        .field{
            position:relative;
            margin-bottom: 0.2em !important;
            &.error{
                .sylius-validation-error {
                    position: absolute;
                    left:0;
                    top:100%;
                    background-color: transparent !important;
                    background: transparent !important; 
                    color: #DB2828 !important;
                    padding-top: 0;
                    margin-top: 0.2em;
                    padding-left:0.5em;

                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
}
.login-page-description{
    margin-bottom: 10px;
}

@media (max-width: 1100px) {
    .login-page-description {
        display: none;
    }
}
.sylius-grid-table-wrapper {
    .wrap-subitem-actions{
        .ui{
            &.buttons:not(:last-child) {
                margin-bottom: 0.8rem;
            }
        }
    }
}